.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;  /* Ensures padding is included in width */
}

.login-form {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;  /* Ensure padding doesn't affect width */
}

.login-btn {
    width: 100%;  /* Ensure the button fills the container like the input */
    padding: 10px;
    background-color: #0069ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;  /* Ensure padding doesn't affect width */
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.signup-section p {
    margin-top: 20px;
}

.signup-section a {
    color: #0069ff;
}

.password-container {
    position: relative;
    width: 100%;
}

.password-container input {
    width: 100%;  /* Make the input field fill the container */
    padding-right: 40px;  /* Add padding to the right to make space for the icon */
    box-sizing: border-box;  /* Make sure padding is included in the element's width */
}

.toggle-password {
    position: absolute;
    right: 10px;  /* Position the icon inside the input field, near the right edge */
    top: 50%;  /* Center the icon vertically */
    transform: translateY(-50%);  /* Ensure the icon stays vertically centered */
    cursor: pointer;
    user-select: none;  /* Prevent text selection when clicking the icon */
    font-size: 18px;  /* Adjust icon size */
    color: #888;  /* Customize icon color */
}

.toggle-password:hover {
    color: #333;  /* Darken the icon on hover */
}

.oauth-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

.oauth-btn {
    width: 100%;
    max-width: 300px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.oauth-btn.google {
    background-color: #4285F4;
    color: white;
}

.oauth-btn.google:hover {
    background-color: #357ae8;
}

.oauth-btn.facebook {
    background-color: #4267B2;
    color: white;
}

.oauth-btn.facebook:hover {
    background-color: #365899;
}

.oauth-btn.microsoft {
    background-color: #2F2F2F;
    color: white;
}

.oauth-btn.microsoft:hover {
    background-color: #1e1e1e;
}

.oauth-btn.apple {
    background-color: black;
    color: white;
}

.oauth-btn.apple:hover {
    background-color: #333;
}
