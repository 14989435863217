/* Import Font Awesome */
@import url('~@fortawesome/fontawesome-free/css/all.min.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Styles for all pages */
body {
  margin: 50;
  font-family: 'Trebuchet MS', sans-serif;
  background-color: #f9f9f9;
  max-width: 1200px; /* Set the max width for the entire grid */
  margin: 0 auto; /* Center the grid */
}

h1, h2 {
  color: #333;
  text-align: center;
}

/*
h1 {
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.5em;
}
*/

p {
  color: #666;
  text-align: center;
}


.back-to-main {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px; /* Add space below the button */
}

.back-link {
  font-size: 1.2em;
  color: #0066cc;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #0066cc;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.back-link:hover {
  background-color: #0066cc;
  color: white;
}


/* Shared grid styles */
.table-grid-3x {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 elements per row */
  gap: 20px;
}

.grid-card-link {
  text-decoration: none; /* Remove underline from links */
}

.grid-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;  
  transition: transform 0.3s ease;  /* Add some hover effect */
  max-width: 300px;  
  width: 100%;  
  margin: 0 auto; /* Center the card within its column */
}

.grid-card:hover {
  transform: scale(1.05);  /* Scale up slightly on hover */
}

.grid-image {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto 10px; 
}

.grid-card h2, .grid-card p {
  margin: 10px 0;
  color: #333; 
}

.grid-card h2 {
  font-size: 1.2em;
}

.grid-card p {
  font-size: 1em;
  color: #666; 
}

@media (max-width: 768px) {
  .table-grid-3x {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (max-width: 480px) {
  .table-grid-3x {
      grid-template-columns: 1fr; /* 1 column on small screens */
  }
}


/* Shared items listing table style */
.items-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.category-section {
  margin-bottom: 40px;
}

.items-table {
  width: 70%;
  min-width: 400px;
  border-collapse: collapse;
  display: block;
  margin: 0 auto; /* Add this line to center the table */
}

.items-table th, .items-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.thumbnail {
  width: 100px;
  height: auto;
}

.error-message {
  color: #e74c3c;  /* Red color for error */
  background-color: #f9d6d5;  /* Light red background to make it stand out */
  border: 1px solid #e74c3c;  /* Red border to emphasize the error */
  padding: 10px;  /* Adds spacing inside the error box */
  border-radius: 5px;  /* Rounded corners for a softer appearance */
  margin-bottom: 10px;  /* Adds space between the error and form elements */
  font-size: 0.9rem;  /* Slightly smaller font size to differentiate it from form labels */
  text-align: center;  /* Align the text to the left */
  width: 300px;
}

.success-message {
  color: #2ecc71;  /* Green color for success */
  background-color: #eafaf1;  /* Light green background */
  border: 1px solid #2ecc71;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  text-align: center;
  width: 300px;
}

.generation-time {
  font-size: 0.875rem;  /* Small font size (14px) */
  color: #6c757d;  /* Subtle gray color */
  margin-top: 1rem;  /* Space above the text */
  text-align: center;  /* Center align the text */
  font-style: italic;  /* Make the text italic for a cleaner look */
}

hr {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
  width: 80%;
}

hr small {
  width: 60%;
}


.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 10px;  /* Adjust space between the item name and the icon */
}

.icon-button i {
  transition: color 0.2s ease;  /* Smooth transition for color changes */
}

.icon-button:hover i {
  color: #0056b3;  /* Darker blue on hover */
}

.icon-button.checkmark:hover i {
  color: #1e7e34;  /* Darker green on hover */
}

.icon-button:disabled i {
  cursor: not-allowed;  /* Disable the cursor for disabled buttons */
  color: #888;  /* Light gray for disabled buttons */
}
