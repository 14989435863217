.avatar-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 200px;
    text-align: center;
}

.avatar-dropdown p {
    margin: 5px 0;
}

.logout-btn {
    background-color: #ff5f5f;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.logout-btn:hover {
    background-color: #e44e4e;
}
