
ul.grocery li {
    list-style: none;  /* Remove default bullet points */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

span.grocery {
    margin: 0px 10px;  /* Space between item name/price and the icon */
}

