.hamburger-container {
    position: relative;
}

.hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 24px;
    cursor: pointer;
}

.bar {
    width: 100%;
    height: 3px;
    background-color: black;
    transition: 0.3s ease;
}

.bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.bar.open:nth-child(2) {
    opacity: 0;
}

.bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.menu-content {
    position: absolute;
    top: 40px;
    left: 0;
    width: 200px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
}

.menu-content ul {
    list-style: none;
    padding: 0;
}

.menu-content li {
    margin-bottom: 15px;
}

.menu-content li a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    transition: color 0.2s ease;
}

.menu-content li a:hover {
    color: #0069ff;
}
