.shopping-icon {
    position: relative;
    margin-right: 20px;  /* Add space between the ShoppingIcon and the Avatar */
    cursor: pointer;
}

.shopping-icon i {
    font-size: 24px;
    color: #333;
}

/* Counter styling */
.item-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: lightgray; /* Default gray */
    color: black;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
}

/* If there are items in the cart, update counter styling */
.item-count.has-items {
    background-color: red;   /* Turns red when items > 0 */
    color: white;
}
