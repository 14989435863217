/* Styling for the general content */
@media (max-width: 768px) {
    .general-page {
        .content {
            margin-top: 50px;
            /* Adjust content margin accordingly */
        }
    }
}

.general-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-top: 60px;
    /* Adjust depending on the height of the TopBar */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.main-content {
    flex: 1;
    padding: 10px;
    text-align: center;
}

.main-btn {
    width: 100px;
    padding: 10px;
    background-color: #0069ff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;
    /* Ensure padding doesn't affect width */
}

.options-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.option-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s ease;
    text-align: left;
}

.option-card:hover {
    transform: translateY(-5px);
}

.option-card h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #0069ff;
}

.option-card p {
    color: #555;
}

.option-card a {
    text-decoration: none;
}


/* Base styles for the container */
.preferences-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; /* Use full viewport height */
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    /* Ensuring a consistent font */
}

/* Styling for the form container */
.form-container {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Label and checkbox styles */
label {
    display: block;
    font-size: 16px;
    color: #333;
    margin: 10px 0;
    cursor: pointer;
}

/* Input field styles */
input[type="checkbox"] {
    margin-right: 10px;
}

/* Button styling */
button.save-btn {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button.save-btn:hover {
    background-color: #0056b3;
}

.save-btn:disabled {
    background-color: #cccccc;
    /* Gray background when disabled */
    color: #666666;
    /* Lighter text color */
    cursor: not-allowed;
    /* Show 'not-allowed' cursor when disabled */
}

.save-btn:disabled:hover {
    background-color: #cccccc;
    /* Gray background when disabled */
}


/* Button styling */
button.load-btn {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 20px;
    margin: 1px 10px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button.load-btn:hover {
    background-color: #0056b3;
}

.selected-shopping-list {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Ensure the ul and li have no bullet points */
.merchant-list {
    list-style-type: none; /* Remove bullets from the main merchant list */
    padding-left: 0;
}

.items-list {
    list-style-type: none; /* Remove bullets from the item list */
    padding-left: 0; 
    margin-left: 0; /* Add some margin for alignment */
}

.item-entry {
    display: flex;
    align-items: center; /* Align icon and text vertically */
    margin-bottom: 10px; /* Add some space between items */
}

/* Ensure button styling is appropriate */
.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px; /* Space between the button and item name */
}

/* Styling for item name to align properly */
.item-name {
    font-size: 16px; /* Adjust font size for readability */
}

/* Optionally add hover effects to the buttons for better user feedback */
.icon-button:hover i {
    opacity: 0.8;
}
