
/* Wrapper to position custom arrow */
.ai-selection {
    position: relative;
    width: 100%;
    max-width: 300px;
    /* Adjust this size according to your container */
    margin-bottom: 1rem;
    font-size: 10px;
}

.ai-selection label {
    font-size: 10px;
}

/* Customizing the dropdown */
.ai-selection select {
    font-size: 12px;
    width: 100%;
    padding: 0.75rem;
    padding-right: 2.5rem;
    /* Give some space for the custom arrow */
    /* font-size: 1rem; */
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #333;
    font-family: 'Roboto', sans-serif;
    appearance: none;
    /* Hide default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: border-color 0.3s ease;
    cursor: pointer;
}

/* Change border and background color on hover */
.ai-selection select:hover {
    border-color: #888;
}

/* Change border on focus */
.ai-selection select:focus {
    border-color: #4CAF50;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

/* Custom dropdown arrow */
/*
.ai-selection::after {
    content: '▼';
    font-size: 0.75rem;
    color: #333;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
*/



/* Optional: For disabled select */
.ai-selection select:disabled {
    background-color: #eaeaea;
    color: #999;
    cursor: not-allowed;
}

