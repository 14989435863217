.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-big-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-close-button {
  position: relative;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24x;
  cursor: pointer;
}

.modal-input {
  width: 95%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.save-modal,
.cancel-modal {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-modal {
  background-color: #007bff;
  color: white;
  margin-left: 10px;
}

.cancel-modal {
  background-color: #ccc;
}


/* List of search results */
.modal-search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;  /* Scrollable if there are too many items */
  overflow-y: auto;
  border: 1px solid #ccc;  /* Border around the result list */
  border-radius: 4px;
}

/* Each search result item */
.modal-search-result-item {
  display: flex;
  justify-content: space-between;  /* Space between item name and action button */
  padding: 10px;
  border-bottom: 1px solid #eee;
  align-items: center;
  font-size: 16px;
}

.modal-search-result-item:hover {
  background-color: #f0f0f0;  /* Hover effect for better UX */
  cursor: pointer;
}

/* Image for the search result items */
.item-image {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 10px;
}

/* Scrollbar for the results list */
.modal-search-results::-webkit-scrollbar {
  width: 8px;
}

.modal-search-results::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.modal-search-results::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* Close button at the top right */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-close-button:hover {
  color: #999;  /* Hover effect for close button */
}