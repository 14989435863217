@media (max-width: 768px) {
    .top-bar {
        height: 40px;
        /* Reduce height for mobile screens */
        padding: 0 10px;
        /* Less padding on mobile */
    }
}

.top-bar {
    position: fixed;
    /* Fix the position at the top of the screen */
    top: 0;
    /* Align with the top of the viewport */
    left: 0;
    right: 0;
    z-index: 1000;
    /* Ensure the TopBar stays above other content */
    background-color: #f8f8f8;
    /* Background color for the TopBar */
    height: 50px;
    /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content: space-between; */
    padding: 0 20px;
    /* Add some horizontal padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add a slight shadow for elevation */
}

/* Flexbox for left and right sections */
.left-section {
    display: flex;
    align-items: left;
}

.right-section {
    display: flex;
    align-items: right;
}